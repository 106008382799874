import React from 'react';

import './Loader.scss'

interface Props {
    fullScreen?: boolean;
}

const styles = {width: '100vw', height: '100vh'};

const Loader: React.FC<Props> = ({fullScreen}) => (
    <div className="lds-ring" style={fullScreen ? styles : {}}>
        {[...new Array(4)].map((_, i) => <div key={i}/>)}
    </div>
);

export default Loader;
