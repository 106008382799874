import React, {useContext, useState} from 'react';
import './MessageInput.scss';
import AppContext from '../../AppContext';

const dict = {
    placeholder: 'Ask me anything...'
};

const MessageInput: React.FC = () => {
    const [submitted, setSubmitted] = useState(false);
    const [fieldValue, setFieldValue] = useState('');

    const appContext = useContext(AppContext);

    const fieldError = () => submitted && !fieldValue ? 'field-error' : '';

    const sendMessage = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && event.nativeEvent.target) {
            event.preventDefault();
            setSubmitted(true);
            if (!appContext.isChatLoading && fieldValue) {
                appContext.sendMessage(fieldValue);
                setSubmitted(false);
                setFieldValue('');
            }
        }
    };

    return (
        <div className="message-input">
            <textarea
                placeholder={dict.placeholder}
                className={`message-input-element ${fieldError()}`}
                onBlur={() => appContext.setInputActiveOff()}
                onFocus={() => appContext.setInputActiveOn()}
                onKeyPress={sendMessage}
                onChange={(e: any) => setFieldValue(e.target.value)}
                value={fieldValue}
            />
            {!appContext.isMobile ? (
                <>
                    <div className="message-input-icon">
                        <i className="message-input-icon-human"/>
                    </div>
                    <i className="message-input-icon-cursor"/>
                </>
            ) : null}
        </div>
    );
};

export default MessageInput
