export default {
    CHAT_CONVERSATION_URL: 'https://staging-runner.zoovu.com/api/v1/channel/chat/',
    CHAT_PRODUCTS_URL: 'https://staging-runner.zoovu.com/api/v1/advisorRunner/recommendation?numberOfProductsPerPage=50',
    ADVISOR_CODE: 'wHljQbmN',
    CHAT_DESCRIPTION_URL: 'https://g7u84h12ll.execute-api.eu-west-1.amazonaws.com/latest/describe',
    PRODUCT_API_URL: 'https://qa10.smartassistant.com/advisor-fe-web/api/v1/advisors/wHljQbmN/products?locale=en-IE&npp=100',
    MOBILE_RESOLUTION: 980,
    WINDOW_HEIGHT_MAX: 99999,
    SELECTION_SIGNAL: 'Customer selected laptop himself'
}
