import React, {useContext, useEffect} from 'react';
import './Tags.scss';
import AppContext from "../../AppContext";
// @ts-ignore
import DragScrollProvider from 'drag-scroll-provider'

const dict = {
    mobileHeader: 'Answer the questions below to find your perfect laptop'
};

const Tags: React.FC = () => {
    const appContext = useContext(AppContext);

    useEffect(() => {
        const wrapper = document.querySelector('.tags .scrollable')
        if (wrapper) wrapper.scrollLeft = wrapper.scrollWidth;
    }, [appContext.entities]);

    const shouldShowMobileHeader = () => appContext.isMobile && !appContext.entities.length;

    return (
        <div className={`tags  ${appContext.isMobile ? 'tags-mobile' : ''}`}>
            {shouldShowMobileHeader() ?
                <p className="tags-mobile-header">{dict.mobileHeader}</p> : null}
            <div
                className={`tags-refresh ${appContext.isMobile ? 'tags-refresh-mobile' : ''}`}>
                <i
                    className="tags-refresh-button"
                    onClick={appContext.restartSession}
                />
            </div>
            <DragScrollProvider>
                {({onMouseDown, ref}: any) => (
                    <div
                        ref={ref}
                        className="scrollable"
                        onMouseDown={onMouseDown}>
                        {appContext.entities.map((tag: any) => (
                            <div key={tag}
                                 className="tags-single-item">{tag}</div>)
                        )}
                        <div className="tags-single-item-last">&nbsp;</div>
                    </div>
                )}
            </DragScrollProvider>
        </div>
    );
}

export default Tags
