import * as React from 'react';
import {SingleMessageI} from './components/singleMessage/types';
import {ChatMessage, Laptop} from './types';

export interface AppContextInterface {
    entities: any,
    flowStep: number,
    sessionId: string,
    selectedLaptop?: Laptop,
    showRecommendation: boolean,
    basket: number[],
    lastResponseTime: number,
    loaded: boolean,
    laptops: any,
    isMobile: boolean,
    isInputActive: boolean,
    setInputActiveOn: () => void
    setInputActiveOff: () => void
    updateSessionId: (sessionId: string) => void,
    restartSession: () => void,
    updateShowRecommendation: (show: boolean) => void,
    updateSelectedLaptop: (laptop: Laptop) => void,
    addToBasket: (item: number) => (void),
    //Chat
    isChatLoading: boolean;
    isProductListFetching: boolean;
    setProductListFetchingOn: () => void,
    setProductListFetchingOff: () => void,
    chatState: SingleMessageI[];
    msgData: ChatMessage;
    context: any;
    waiting: boolean;
    isListening: boolean;
    isSpeechRecognitionSupported: boolean;
    toggleVoiceRecognition: Function;
    sendMessage: (message: any) => void;
}

export default React.createContext<AppContextInterface>({
    entities: [],
    flowStep: 0,
    addToBasket: function (p1: number) {
    },
    basket: [],
    chatState: [],
    context: undefined,
    isChatLoading: false,
    isProductListFetching: false,
    setProductListFetchingOn: () => {},
    setProductListFetchingOff: () => {},
    isListening: false,
    isMobile: false,
    isInputActive: false,
    setInputActiveOn: () => {},
    setInputActiveOff: () => {},
    isSpeechRecognitionSupported: false,
    laptops: undefined,
    lastResponseTime: 0,
    loaded: false,
    selectedLaptop: {id: 0, name: '', picture: ''},
    sendMessage: function (p1: SingleMessageI) {
    },
    sessionId: "",
    showRecommendation: false,
    toggleVoiceRecognition: function () {
        return {};
    },
    updateSelectedLaptop: function (p1: Laptop) {
    },
    updateSessionId: function (p1: string) {
    },
    restartSession: function () {
    },
    updateShowRecommendation: function (p1: boolean) {
    },
    waiting: false,
    msgData: {
        initialMessage: '',
        lastMessage: ''
    }
});
