import React, {useState, useContext, useEffect} from 'react';
import './ProductList.scss';
import ProductTile from "../productTile/ProductTile";
import AppContext from "../../AppContext"
import Loader from '../loader/Loader';
import TopProduct from '../topProduct/TopProduct';
import TopProductMobile from '../topProduct/TopProductMobile';

const dict = {
    topHeader: ' Our top recommendation for you:',
    otherHeader: 'Other products that match most of your needs:'
};

const FLOW_STEPS_CLASSES_DESKTOP: any = {
    0: 'FiveInRow',
    1: 'FourInRow',
    2: 'ThreeInRow',
    3: 'TopProduct'
};

const FLOW_STEPS_CLASSES_MOBILE: any = {
    0: 'ThreeInRow',
    1: 'ThreeInRow',
    2: 'TwoInRow',
    3: 'TopProduct'
};

const FLOW_LAST_STEP = 3;

const ProductList: React.FC = () => {
    const appContext = useContext(AppContext);
    const FLOW_STEPS_CLASSES = appContext.isMobile ? FLOW_STEPS_CLASSES_MOBILE : FLOW_STEPS_CLASSES_DESKTOP

    const [gridVariant, setGridVariant] = useState(FLOW_STEPS_CLASSES[0]);
    const itemClassName = 'product-list-item ' + gridVariant;
    const toggleGrid = () => setGridVariant(FLOW_STEPS_CLASSES[appContext.flowStep]);
    const isLastStep = () => appContext.flowStep === FLOW_LAST_STEP;
    const isStep = (stepName: string) => FLOW_STEPS_CLASSES[appContext.flowStep] === stepName;
    const products = () => {
        if (isLastStep() && !appContext.isMobile) return appContext.laptops.slice(1)
        if (isStep('SixInRow')) return appContext.laptops.slice(0, 24)
        if (isStep('ThreeInRow') && appContext.isMobile) return appContext.laptops.slice(0, 6)
        if (isStep('TwoInRow') && appContext.isMobile) return appContext.laptops.slice(0, 4)
        if (isStep('TopProduct') && appContext.isMobile) return []
        return appContext.laptops;
    }

    useEffect(() => {
        toggleGrid()
    }, [appContext.flowStep]);

    return appContext.laptops.length ? (
        <>
            {isLastStep() && !appContext.isMobile ? (
                <>
                    <h2 className="last-step-header">
                        {dict.topHeader}
                    </h2>
                    <div
                        onClick={() => appContext.updateSelectedLaptop(appContext.laptops[0])}
                    >
                        <TopProduct laptop={appContext.laptops[0]}/>
                    </div>
                    <h2 className="last-step-header">
                        {dict.otherHeader}
                    </h2>
                </>
            ) : null}

            {isLastStep() && appContext.isMobile
                ? (<TopProductMobile laptop={appContext.laptops[0]}/>)
                : null }

            <div className="product-list">
                {products().map((laptop: any) => (
                    <div
                        key={laptop.id}
                        className={itemClassName}
                        onClick={() => appContext.updateSelectedLaptop(laptop)}>
                        <ProductTile laptop={laptop}/>
                    </div>
                ))}
            </div>

        </>
    ) : !appContext.isMobile ? <Loader/> : null;
};

export default ProductList
