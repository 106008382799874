import React, {useContext} from 'react';
import './MessageList.scss';
import SingleMessage from '../singleMessage/SingleMessage';
import AppContext from '../../AppContext';

const MessageList: React.FC = () => {
    const appContext = useContext(AppContext);
    return (
        <div className="message-list">
            {appContext.chatState.map((message, index) => (
                <SingleMessage key={index} messageObject={message}/>)
            )}
        </div>
    );
};

export default MessageList
