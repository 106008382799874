import React from 'react';
import cortanaIcon from "../../assets/icons/icon_cortana.svg";
import digitalPenIcon from "../../assets/icons/icon_digital_pen.svg";
import helloIcon from "../../assets/icons/icon_hello.svg";
import securityIcon from "../../assets/icons/icon_security.svg";
import touchScreenIcon from "../../assets/icons/icon_touchscreen.svg";

const ICON_SIZE = 20;

const ProductIcons = () => (
    <>
        <img src={cortanaIcon} alt='cortanaIcon' width={ICON_SIZE}/>
        <img src={digitalPenIcon} alt='digitalPenIcon' width={ICON_SIZE}/>
        <img src={helloIcon} alt='helloIcon' width={ICON_SIZE}/>
        <img src={securityIcon} alt='securityIcon' width={ICON_SIZE}/>
        <img src={touchScreenIcon} alt='touchScreenIcon' width={ICON_SIZE}/>
    </>
);

export default ProductIcons;
