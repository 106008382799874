import React from 'react';
import {LaptopObject} from '../../types';
import './TopProduct.scss';
import ProductIcons from '../productIcons/ProductIcons';
import checkboxIcon from '../../assets/icons/checkbox_unselected.svg';
import emailIcon from '../../assets/icons/icon_mail.svg';
import positiveIcon from '../../assets/icons/icon_positive_attribute.svg';
import arrowDownIcon from '../../assets/icons/icon_arrow_dropdown.svg';
import {priceFormater} from '../../utils'

interface Props {
    laptop: LaptopObject
}

const dict = {
    emailBtn: 'email me my match',
    compareBtn: 'compare',
    buyButton: 'buy now',
    attrsLabel: 'Attributes matching your requirements:',
    attrsBtn: 'more attributes',
    noProp: 'No property'
};

const TopProduct: React.FC<Props> = ({laptop}) => {
    const price = priceFormater(Number(laptop.price))
    const lookForProps = () => {
        return laptop.positiveProperties.length ?
            laptop.positiveProperties.map(({property, value}: any) => `${property}: ${value}`)
            : [dict.noProp, dict.noProp, dict.noProp];
    };

    const openInNewTab = () => {
        const win = window.open(laptop.offerurl, '_blank');
        if (win) win.focus();
    }

    return (
        <div className="top-product-tile">
            <div className="top-product-tile-body">
                <div className="top-product-tile-body-image">
                    <img className="product-tile-picture"
                         src={laptop.picture}
                         alt="top product"
                    />
                </div>
                <div className="top-product-tile-body-details">
                    <section
                        className="top-product-tile-body-details-first-section">
                        <div><p>{laptop.name}</p> <p className="top-product-tile-body-details-first-section-price">${price}</p></div>
                    </section>
                    <section
                        className="top-product-tile-body-details-second-section">
                        <div>
                            <p>{dict.attrsLabel}</p>
                            <ul>
                                {lookForProps().map((property: any, index: number) => (
                                    <li key={index}>
                                        <img
                                            src={positiveIcon}
                                            alt="checkbox"
                                        />
                                        {property}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
            <div className="top-product-tile-footer">
                <div className="top-product-tile-footer-icons">
                    <p className="more-attrs">
                        {dict.attrsBtn}
                        <img src={arrowDownIcon} alt="checkbox" width={20}/>
                    </p>
                    <ProductIcons/>
                </div>
                <div className="top-product-tile-footer-buttons">
                    {/*<button className="compare">*/}
                    {/*    <img src={checkboxIcon} alt="checkbox" width={15}/>*/}
                    {/*    {dict.compareBtn}*/}
                    {/*</button>*/}
                    {/*<button className="email-my-match">*/}
                    {/*    <img src={emailIcon} alt="checkbox" width={15}/>*/}
                    {/*    {dict.compareBtn}*/}
                    {/*</button>*/}
                    <button className="buy-now" onClick={openInNewTab}>
                        {dict.buyButton}
                    </button>
                </div>
            </div>
        </div>
    )
};

export default TopProduct;
