import React, {useContext} from 'react';
import './Header.scss';
import AppContext from '../../AppContext';

const Header: React.FC = () => {
    const appContext = useContext(AppContext);
    return (
        <div className="header">
            <section className="header-logo">
                    <i className="header-logo-icon"/>
            </section>
            <section className="header-nav">
                <ul className="header-nav-list">
                    <li className="header-nav-list-item">Store</li>
                    <li className="header-nav-list-item">Products</li>
                    <li className="header-nav-list-item">Support</li>
                </ul>
            </section>
            <section className="header-right-section">
                <section className="header-search">
                    <input className="header-search-input" type="text"/>
                    <i className="header-search-icon"/>
                </section>
                <section className="header-basket">
                    <i className="header-basket-icon"/>
                    <sub className="header-basket-value">{appContext.basket.length}</sub>
                </section>
                <section className="header-sign-in">
                    <a className="header-sign-in-link" href="#">Sign in</a>
                </section>
            </section>
        </div>
    );
}

export default Header
