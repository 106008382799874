import React, {useContext} from 'react';
import './FlipToPortraitModal.scss';
import flipToPortraitIcon from '../../assets/icons/flip_to_portrait.svg';
import AppContext from '../../AppContext';

const FlipToPortraitModal: React.FC = () => {
    const appContext = useContext(AppContext);
    const modal = !appContext.isInputActive
        ? (
            <div className="flip-to-portrait-modal">
            <img src={flipToPortraitIcon} alt="flip icon"/>
            <span>flip to portrait view</span>
        </div>)
        : null
    return (<>{modal}</>);
}

export default FlipToPortraitModal
