import React, {useContext, useEffect, useState} from 'react';
import './VoiceRecognitionSwitcher.scss';
import AppContext from '../AppContext';

const VoiceRecognitionSwitcher: React.FC = () => {

    const appContext = useContext(AppContext);

    // TODO do we need this commented logic ?
    // const [isChatWidgetVisible, setIsChatWidgetVisible] = useState<boolean>(false);
    // useEffect(() => {
    //     document.addEventListener('scroll', () => {
    //         const status = isScrolledIntoView();
    //         setIsChatWidgetVisible(status);
    //     });
    //     return document.removeEventListener('scroll', () => {});
    // }, []);
    //
    // useEffect(() => {
    //     if (appContext.msgData.lastMessage) {
    //         const status = isScrolledIntoView();
    //         setIsChatWidgetVisible(status);
    //     }
    // }, [appContext.msgData.lastMessage]);

    // const isScrolledIntoView = () => {
    //     const el = document.querySelector('.chat-widget-wrapper');
    //     if (el) {
    //         const {top, bottom} = el.getBoundingClientRect();
    //         return top < window.innerHeight && bottom >= 0;
    //     }
    //     return true
    // };

    const handleClick = () => {
        // appContext.scrollToBottomOfConversationBox();
        appContext.toggleVoiceRecognition();
    };

    return appContext.isSpeechRecognitionSupported ? (
        <div className="voice-recognition-switcher-wrapper">
            <div className="voice-recognition-switcher-button-wrapper">
                <button
                    className={`voice-recognition-switcher-button ${appContext.isListening ? 'on' : 'off'}`}
                    type="button"
                    onClick={handleClick}
                >
                    <i className="voice-recognition-switcher-button-icon"/>
                </button>
            </div>
        </div>
    ) : null
};

export default VoiceRecognitionSwitcher;
