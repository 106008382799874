import React, {useState} from 'react';
import './SingleMessage.scss';
import {SingleMessageProps} from './types'


const SingleMessage: React.FC<SingleMessageProps> = ({messageObject}) => {
    const {text, type} = messageObject;
    const icon = type === 'bot'
        ? <i className="single-message-icon-bot"/>
        : <i className="single-message-icon-human"/>
    const singleMessageClassName = type === 'bot'
        ? 'single-message bot'
        : 'single-message human'
    let content
    if (text.startsWith('pic:')){
        const url = text.slice(4)
        content = <img src={url} />
    } else {
        content = text
    }
    return (
        <div className={singleMessageClassName}>
            <div className="single-message-icon">
                {icon}
            </div>
            <div className="single-message-text">
                {content}
            </div>
        </div>
    );
};

export default SingleMessage
