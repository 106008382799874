import React from 'react';
import './TopProductMobile.scss';
import {ProductTileProps} from '../productTile/types';
import {priceFormater} from "../../utils";

const dict = {
    buyButton: 'buy now'
};

const TopProductMobile: React.FC<ProductTileProps> = ({laptop}) => {
    const price = priceFormater(Number(laptop.price))
    const openInNewTab = () => {
        const win = window.open(laptop.offerurl, '_blank');
        if (win) win.focus();
    }

    return (
        <div className="top-product-mobile-tile">
            <div className="top-product-mobile-tile-body">
                <div className="top-product-mobile-tile-image-wrapper">
                    <img className="top-product-mobile-tile-image"
                         src={laptop.picture}
                         alt="top product"
                    />
                    <p className="top-product-mobile-tile-price">
                        {`$${price}`}
                    </p>
                </div>
                <div className="top-product-mobile-tile-content-wrapper">
                    <div className="top-product-mobile-tile-name">
                        {laptop.name}
                    </div>
                    <div className="top-product-mobile-tile-attributes">
                        <ul className="top-product-mobile-tile-attributes-list">
                            <li className="top-product-mobile-tile-attributes-item">OS: {laptop.operatingSystem}</li>
                            <li className="top-product-mobile-tile-attributes-item">Screen size: : {laptop.screenSizeInInches}</li>
                            <li className="top-product-mobile-tile-attributes-item">Memory: {laptop.installedRAM}</li>
                            <li className="top-product-mobile-tile-attributes-item">Storage:: {laptop.primaryStorageCapacity}</li>
                            <li className="top-product-mobile-tile-attributes-item">Battery life: {laptop.batteryLifeInHour}</li>
                        </ul>
                    </div>
                    <div className="top-product-mobile-tile-button-wrapper">
                        <button className="top-product-mobile-tile-button"
                                onClick={openInNewTab}>{dict.buyButton}</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TopProductMobile;
