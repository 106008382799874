import React, {useContext, useEffect, useState} from 'react';
import './Desktop.scss';
import ProductList from '../components/productList/ProductList';
import VoiceRecognitionSwitcher
    from '../voiceRecognition/VoiceRecognitionSwitcher';
import Chat from '../components/chat/Chat';
import Tags from '../components/tags/Tags';
import './Mobile.scss';
import MessageInput from "../components/messageInput/MessageInput";
import FlipToPortraitModal
    from "../components/flipToPortraitModal/FlipToPortraitModal";
import arrowDownIcon from '../assets/icons/icon_arrow_dropdown_white.svg';
import AppContext from "../AppContext";
import Config from "../config";
import Loader from "../components/loader/Loader";
import MessageLoader from "../components/messageLoader/MessageLoader";

const Mobile: React.FC = () => {

    const [showProducts, setShowProducts] = useState(false)
    const headerClassName = 'assistant-mobile-layout-header-part ' + (showProducts ? 'on' : 'off')
    const messagesWrapperClassName = 'assistant-mobile-layout-messages-wrapper ' + (showProducts ? 'on' : 'off')
    const productToggleButtonClassName = 'assistant-mobile-layout-product-toggle-button ' + (showProducts ? 'on' : 'off')
    const messagesWrapperRef = React.createRef<HTMLElement>();
    const toggleShowProducts = () => {
        setShowProducts((prevShow: boolean) => !prevShow)
    };
    const appContext = useContext(AppContext);

    const scrollMaxBottom = () => {
        const ref = messagesWrapperRef.current
        const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        if (appContext.isMobile && ref) {
            const contentIsLongerThanViewport = ref.clientHeight > viewportHeight
            const windowHeightMax = Config.WINDOW_HEIGHT_MAX
            if (contentIsLongerThanViewport) {
                window.scrollTo(0, windowHeightMax)
            }
        }
    };

    useEffect(() => {
        scrollMaxBottom()
    }, [appContext.chatState]);

    useEffect(() => {
        scrollMaxBottom()
    }, [appContext.waiting]);

    return appContext.isChatLoading && !appContext.loaded ?
        <Loader fullScreen/> : (
            <section className="assistant-mobile-layout">
                <section className={headerClassName}>
                    <Tags/>
                    <div
                        className="assistant-mobile-layout-main-product-wrapper">
                        {!appContext.isProductListFetching ? <ProductList/> : <MessageLoader/> }
                    </div>
                </section>
                <section className={messagesWrapperClassName}
                         ref={messagesWrapperRef}>
                    <Chat/>
                </section>
                <section className="assistant-mobile-layout-footer-wrapper">
                    <MessageInput/>
                </section>
                <section>
                    <div className={productToggleButtonClassName} onClick={toggleShowProducts}>
                        <img src={arrowDownIcon} alt="arrowDownIcon" width={20}/>
                    </div>
                </section>
                <VoiceRecognitionSwitcher/>
                <FlipToPortraitModal/>
            </section>
        );
};

export default Mobile;
