import React, {useContext, useEffect, useState} from 'react';
import './ProductTile.scss';
import {ProductTileProps} from './types';
import AppContext from '../../AppContext';
import {priceFormater} from '../../utils'

const ProductTile: React.FC<ProductTileProps> = ({laptop}) => {
    const [toggleMobileClass, setToggleMobileClass] = useState(false);

    const appContext = useContext(AppContext);

    const handleClick = (e:any) => {
        if (appContext.isMobile) setToggleMobileClass(!toggleMobileClass);
        else openInNewTab()
    };

    const price = priceFormater(Number(laptop.price))

    const openInNewTab = () => {}

    useEffect(() => {
        setToggleMobileClass(false);
    }, [appContext.isChatLoading]);
    const isSelectedLaptop = appContext.selectedLaptop && appContext.selectedLaptop.id === laptop.id
    return (
        <div
            className={`${toggleMobileClass ? 'product-tile-wrapper-mobile' : 'product-tile-wrapper '}`}
            onClick={handleClick}
        >
            <div className="flipper">
                <div className={'product-tile' + (isSelectedLaptop ? ' selected-product' : '')}>
                    <div className="product-tile-front product-tile-inner-box">
                        <img className="product-tile-picture"
                             src={`${laptop.picture}#${new Date().getTime()}`}
                             alt={laptop.name}/>
                        <h2 className="product-tile-name">{laptop.name}</h2>
                    </div>
                    <div
                        className="product-tile-back product-tile-inner-box back">
                        <h2 className="product-tile-back-name">{laptop.name}</h2>
                        <ul className="product-tile-attributes">
                            <li className="product-tile-attributes-item">OS: {laptop.operatingSystem}</li>
                            <li className="product-tile-attributes-item">Screen size: {laptop.screenSizeInInches}-inch</li>
                            <li className="product-tile-attributes-item">Memory: {laptop.installedRAM}</li>
                            <li className="product-tile-attributes-item">Storage: {laptop.primaryStorageCapacity}</li>
                            {/*<li className="product-tile-attributes-item">Processor Brand: {`${laptop.processorBrand}`}</li>*/}
                        </ul>
                        <div className="product-tile-button">
                            <span>{`$${price} | BUY NOW`}</span>
                            <i className="product-tile-button-icon"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductTile
