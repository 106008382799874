export const extractChatState = (text: string[], fn?: Function) => text.reduce((chatState: any, m: string) => {
    if (fn) fn(m);
    return hasImgMsg(m) ? [...chatState] : [...chatState, {
        text: m,
        type: 'bot'
    }];
}, []);

export const extractLastMsg = (text: string[]) => text.slice(-1)[0] || '';

export const findResult = (data: any, compareKey: string) => data.find((i: any) => i.context === compareKey);

export const hasNoVoiceTagInLocationHash = () => window.location.hash.split('#').includes('novoice');

export const hasImgMsg = (message: string) => message.includes('<img');

export const priceFormater = function(number:any, n?:any, x?:any) {
    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    return number.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};
