import React, {useContext, useEffect, useState} from 'react';
import './VoiceRecognitionInfoMask.scss';
import AppContext from "../AppContext";
import {readMessages} from '../App';

const labels = {
    title: 'Voice Recognition is working for this Browser/Device',
    button: 'Please continue'
};

const VoiceRecognitionInfoMask: React.FC = () => {
    const [modalVisibility, setModalVisibility] = useState(false);
    const appContext = useContext(AppContext);

    useEffect(() => {
        if (!appContext.isChatLoading && appContext.loaded) {
            setModalVisibility(true);
        }
    }, [appContext.isChatLoading, appContext.loaded]);

    const closeModal = () => {
        readMessages(appContext.chatState);
        setModalVisibility(false);
    };

    return modalVisibility ? (
        <div className={`voice-modal ${modalVisibility ? 'on' : 'off'}`}>
            <div className="voice-modal-container">
                <h2>{labels.title}</h2>
                {/*<p>{message}</p>*/}
                <button
                    className="voice-modal-button"
                    onClick={closeModal}
                >
                    {labels.button}
                </button>
            </div>
        </div>
    ) : null
};

export default VoiceRecognitionInfoMask;
