import React, {useState} from 'react';
import './MessageLoader.scss';

const MessageLoader: React.FC = () => {
    return (
        <section className="lds-ellipsis-wrapper">
            <div className="lds-ellipsis">
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </section>
    );
}

export default MessageLoader
