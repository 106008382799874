import React, { useEffect, useState } from 'react';
import AppContext from './AppContext';
import CONFIG from './config';
import './App.css'
import productService from './services/ProductsService';
import { SingleMessageI } from './components/singleMessage/types';
import { ChatService } from "./components/chat/ChatService";
import VoiceRecognitionService, { testIfSpeechRecognitionSupported } from "./voiceRecognition/VoiceRecognitionService";
import { extractChatState, extractLastMsg, findResult } from './utils';
import { ChatMessage, ChatState, Laptop } from './types';
import Assistant from "./pages/assistant/Assistant";

export let voiceRecognitionService: any;
const chatService: ChatService = new ChatService();

if (!voiceRecognitionService) {
    voiceRecognitionService = new VoiceRecognitionService({});
}

// TODO refactor - trigger this function below instead directly call voiceRecognitionService.readOutLoud
export const readMessages = (messages: ChatState[]) =>
    messages.forEach((message: ChatState) => voiceRecognitionService.readOutLoud(message.text));

const App: React.FC = () => {
    const [showRecommendation, setShowRecommendation] = useState<boolean>(false);
    const [selectedLaptop, setSelectedLaptop] = useState<Laptop>();
    const [flowStep, setFlowStep] = useState(0);
    const [basket, setBasket] = useState<number[]>([]);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isInputActive, setIsInputActive] = useState<boolean>(false);
    const [sessionId, setSessionId] = useState<string>('');
    const [lastResponseTime, setLastResponseTime] = useState<number>(Date.now());
    // TODO add interface for Laptop Laptop[]
    const [laptops, setLaptops] = useState<any>([]);
    const [loaded, setLoaded] = useState<boolean>(false);

    //Chat
    const [isChatLoading, setIsChatLoading] = useState<boolean>(true);
    const [isProductListFetching, setIsProductListFetching] = useState<boolean>(false);
    const [chatState, setChatState] = useState<SingleMessageI[]>([]);
    const [entities, setEntities] = useState<any>([]);
    const [msgData, setMsgData] = useState<ChatMessage>({
        initialMessage: '',
        lastMessage: ''
    });
    const [context, setContext] = useState<any>({});
    const [waiting, setWaiting] = useState<boolean>(false);
    const [isListening, setIsListening] = useState<boolean>(false);
    const [isSpeechRecognitionSupported, setIsSpeechRecognitionSupported] = useState<boolean>(false);

    voiceRecognitionService.registerCallback('sendMessage', (msg: any) => sendMessage(msg));
    voiceRecognitionService.registerCallback('toggleVoiceRecognition', () => toggleVoiceRecognition());

    useEffect(() => {
        const resize = () => setIsMobile(window.innerWidth <= CONFIG.MOBILE_RESOLUTION);
        window.addEventListener('resize', resize);
        resize();
        initiateChatConversation();
    }, []);

    useEffect(() => {
        fetchLaptops();
    }, [sessionId, lastResponseTime]);

    useEffect(() => {
        if (selectedLaptop) {
            updateConversationContext('selectedLaptop', selectedLaptop.id);
            setWaiting(true);

            const messages = ["This is a customer favorite"];
            if (messages) {
                insertMessages([`You selected the ${selectedLaptop.name}`, `pic:${selectedLaptop.picture}`].concat(messages));
                messages.forEach((m: string) => voiceRecognitionService.readOutLoud(m))
            }
            setWaiting(false);

        }
    }, [selectedLaptop]);

    const fetchLaptops = () => {
        setIsProductListFetching(true);
        productService.getProducts(sessionId)
            .then((otherMatches) => {
                setLaptops(otherMatches);
                setLoaded(true);
                setIsProductListFetching(false)
            })
            .catch(() => {
                setLoaded(true)
                setIsProductListFetching(false)
            });
    };

    const updateSessionId = (sessionId: string) => {
        const lastResponseTime = Date.now();
        setSessionId(sessionId);
        setLastResponseTime(lastResponseTime)
    };

    const restartSession = () => {
        updateSessionId('');
        initiateChatConversation();
    };

    const updateSelectedLaptop = (laptop: Laptop) => setSelectedLaptop(laptop);

    const updateShowRecommendation = (show: boolean) => setShowRecommendation(show);

    const addToBasket = (item: number) => {
        if (basket.indexOf(item) === -1) {
            setBasket([...basket, item]);
        }
    };

    const initiateChatConversation = () => {
        setIsChatLoading(true);
        chatService.initiateConversation()
            .then((data) => {
                const texts = data.responses.filter((r: any) => r.messages).flatMap((r: any) => r.messages);
                setIsChatLoading(false);
                setChatState(
                    extractChatState(texts)
                );
                setMsgData({
                    ...msgData,
                    lastMessage: extractLastMsg(texts),
                    initialMessage: texts.join()
                });
                setContext(data.sessionId);
                updateSessionId(data.sessionId);
                setIsSpeechRecognitionSupported(testIfSpeechRecognitionSupported());
                setFlowStep(0);
                setIsChatLoading(false)
            })
            .catch(() => handleError())
    };

    const handleConversationResponse = (responses: any[], userMsg?: any) => {
        const texts = responses.filter((r: any) => r.messages).flatMap((r: any) => r.messages);
        setLastResponseTime(Date.now())
        setWaiting(false);
        setContext(context);
        setMsgData({ ...msgData, lastMessage: extractLastMsg(texts) });
        setChatState(userMsg ? [...chatState, userMsg, ...extractChatState(texts, voiceRecognitionService.readOutLoud)]
            : [...chatState, ...extractChatState(texts, voiceRecognitionService.readOutLoud)]
        );
    };

    const updateConversationContext = (key: string, value: any) => {
        if (context && context.clever && context.clever.context) {
            const cleverContext = context.clever.context;
            let contextValue = findResult(cleverContext, key);

            if (contextValue === undefined) {
                contextValue = { context: key, contextType: 'STRING' };
                cleverContext.push(contextValue)
            }

            contextValue.value = value ? value.toString() : '';
            return cleverContext
        }
        return {}
    };

    const insertMessages = (messages: string[]) => {
        setChatState([...chatState, ...extractChatState(messages)]);
    };

    const handleError = () => setIsChatLoading(false);

    const sendChatMessage = (msg: string, ctx: any, userMsg?: any) => {
        chatService.sendMessage(msg, ctx)
            .then(response => handleConversationResponse(response.responses, userMsg))
            .catch(() => handleError());
    };

    const sendMessage = (message: any) => {
        const visitorMsg = { text: message, type: 'visitor' };

        setChatState([...chatState, visitorMsg]);
        setWaiting(true);

        sendChatMessage(message, context, visitorMsg);
    };

    const toggleVoiceRecognition = () => {
        if (isListening) voiceRecognitionService.disableVoiceRecognition();
        else voiceRecognitionService.enableVoiceRecognition();
        setIsListening(!isListening);
    };

    const setInputActiveOn = () => setIsInputActive(true)
    const setInputActiveOff = () => {
        setTimeout(() => {
            setIsInputActive(false)
        }, 400)
    }
    const setProductListFetchingOn = () => setIsProductListFetching(true)
    const setProductListFetchingOff = () => setIsProductListFetching(false)

    const setAppContextValues = () => ({
        entities,
        flowStep,
        msgData,
        basket,
        loaded,
        sessionId,
        lastResponseTime,
        showRecommendation,
        selectedLaptop,
        laptops,
        isMobile,
        setInputActiveOn,
        setInputActiveOff,
        isInputActive,
        updateSessionId,
        updateSelectedLaptop,
        updateShowRecommendation,
        addToBasket,
        restartSession,
        //Chat
        isChatLoading,
        isProductListFetching,
        setProductListFetchingOn,
        setProductListFetchingOff,
        chatState,
        ...msgData,
        context,
        waiting,
        isListening,
        isSpeechRecognitionSupported,
        toggleVoiceRecognition,
        sendMessage,
    });

    return (
        <AppContext.Provider value={setAppContextValues()}>
            <Assistant />
        </AppContext.Provider>
    );
};

export default App;
