import CONFIG from '../../config';

const defaultHeaders = new Headers({
    'Content-Type': 'application/json',
});

export class ChatService {
    serviceUrl: string;
    advisorCode: string;
    locale = 'en-IE';

    constructor() {
        this.serviceUrl = `${CONFIG.CHAT_CONVERSATION_URL}`;
        this.advisorCode = `${CONFIG.ADVISOR_CODE}`;
    }

    public getLaptopDescription(selectedLaptopId: number, context: any) {
        const cleverContexts = context && context.clever ? context.clever.context : []
        return fetch(
            CONFIG.CHAT_DESCRIPTION_URL,
            {
                method: 'post',
                headers: defaultHeaders,
                mode: 'cors',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(
                    {
                        laptop: selectedLaptopId,
                        cleverContexts: cleverContexts
                    })
            }
        ).then(response => response.json())
    }

    public summarizeContext(context: any): any {
        const requirements: any = {}
        if (context.clever && context.clever.context) {
            context.clever.context.forEach((c: any) => {
                if (c.value && c.value !== "undefined") {
                    requirements[c.context] = c.value
                }
            })
        }
        return requirements
    }

    public initiateConversation() {
        return fetch(
            this.serviceUrl + 'new?pluginMode=false&origin=BROWSER',
            {
                method: 'post',
                headers: defaultHeaders,
                body: JSON.stringify({
                    advisorCode: this.advisorCode,
                    disableTracking: true,
                    locale: this.locale
                })
            })
            .then(response => response.json())
    }

    public sendMessage(message: string, sessionId: string) {
        const body = JSON.stringify(
            {
                text: message
            })
        return fetch(this.serviceUrl + "message",
            {
                method: 'post',
                headers: {
                    'content-type': 'application/json',
                    "runner-session-id": sessionId
                },
                body: body
            })
            .then(response => response.json())
    }
}
