import CONFIG from '../config';
import { priceFormater } from '../utils';

class ProductsService {

    mapToLaptop(instance: any):any {
        return {
            name: instance.name.value,
            picture: instance.picture.value,
            price: instance.price.value,
            id: instance.identifier
        }
    }

    getProducts(sessionId?:string) {
        if (sessionId){
            return fetch(`${CONFIG.CHAT_PRODUCTS_URL}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'runner-session-id': sessionId
                }
            }).then(response => response.json())
                .then(r => r.recommendations.map((i:any) => this.mapToLaptop(i)));
        }
        return fetch(`${CONFIG.PRODUCT_API_URL}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(response => response.json())
            .then(r => r.productClusters[0].productInstances.map((i:any) => this.mapToLaptop(i)));

    }

}

export default new ProductsService();
