import React, {memo, useContext} from 'react';
import AppContext from '../../AppContext';
import Desktop from '../../assistant/Dektop';
import Mobile from '../../assistant/Mobile';
import VoiceRecognitionInfoMask
    from "../../voiceRecognition/VoiceRecognitionInfoMask";
import {hasNoVoiceTagInLocationHash} from "../../utils";

const Assistant = () => {
    const appContext = useContext(AppContext);
    return (
        <>
            {!hasNoVoiceTagInLocationHash() ? <VoiceRecognitionInfoMask/> : null}
            {appContext.isMobile ? <Mobile/> : <Desktop/>}
        </>
    );
};

export default memo(Assistant);
