import {hasImgMsg, hasNoVoiceTagInLocationHash} from "../utils";

const VOICES = {
    MALE: 3,
    FEMALE: 3
};

const setSpeech = () => {
    return new Promise(
        function (resolve, reject) {
            let synth = window.speechSynthesis;
            let id: any;
            id = setInterval(() => {
                if (synth.getVoices().length !== 0) {
                    resolve(synth.getVoices());
                    clearInterval(id);
                }
            }, 10);
        }
    )
};

const takeSpeechRecognitionInstance = () => {
    return (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
};

export const testIfSpeechRecognitionSupported = () => {
    if (hasNoVoiceTagInLocationHash()) {
        console.log('No browser support for voice recognition');
        return false;
    }
    return !!takeSpeechRecognitionInstance();
};

class VoiceRecognitionService {
    private recognitionService: any
    private callbacks: Map<string, (...args: any[]) => void> = new Map();

    constructor(protected state: any) {
        this.enableRecognition();
    }

    public registerCallback(callbackName: string, callback: (msg: string) => void) {
        this.callbacks.set(callbackName, callback);
    }

    public async readOutLoud(message: string) {
        if (!testIfSpeechRecognitionSupported() || hasImgMsg(message)) return;
        message = message.replace('<br />', '');
        await setSpeech();
        const speech = new SpeechSynthesisUtterance();
        const voices = window.speechSynthesis.getVoices();
        speech.voice = voices[VOICES.FEMALE];
        speech.text = message;
        speech.lang = 'en-US';
        speech.volume = 1;
        speech.rate = 1;
        speech.pitch = 1;
        window.speechSynthesis.speak(speech)
    }

    public enableRecognition() {
        if (!testIfSpeechRecognitionSupported()) return;
        const SpeechRecognition = takeSpeechRecognitionInstance();
        this.recognitionService = new SpeechRecognition();
        this.recognitionService.continuous = true;
        this.recognitionService.lang = 'en-US';
        this.bindRecognitionListeners();
    }

    private bindRecognitionListeners() {
        this.bindRecognitionOnResult();
        this.bindRecognitionOnStart();
        this.bindRecognitionOnSpeechEnd();
        this.bindRecognitionOnError();
    }

    private bindRecognitionOnResult() {
        this.recognitionService.onresult = (event: any) => {
            const current = event.resultIndex;
            const transcript = event.results[current][0].transcript;
            const mobileRepeatBug = (current === 1 && transcript === event.results[0][0].transcript);
            const updateTextFieldCallback = this.callbacks.get('sendMessage')
            const toggleVoiceRecognitionCallback = this.callbacks.get('toggleVoiceRecognition')
            if (typeof toggleVoiceRecognitionCallback === 'function') {
                toggleVoiceRecognitionCallback();
            }
            if (!mobileRepeatBug && typeof updateTextFieldCallback === 'function') updateTextFieldCallback(transcript)
        };
    }

    private bindRecognitionOnStart() {
        this.recognitionService.onstart = () => {
            console.log('Voice recognition activated. Try speaking into the microphone.');
        }
    }

    private bindRecognitionOnSpeechEnd() {
        this.recognitionService.onspeechend = () => {
            console.log('Voice recognition was turned off itself.');
            this.disableVoiceRecognition()
        }
    }

    private bindRecognitionOnError() {
        this.recognitionService.onerror = (event: any) => {
            if (event.error === 'no-speech') {
                console.log('No speech was detected. Try again.');
            }
            // TODO do we need to trigger function below?
            // this.disableVoiceRecognition()
        }
    }

    public disableVoiceRecognition() {
        this.recognitionService.stop()
    }

    public enableVoiceRecognition() {
        this.recognitionService.start()
    }

}

export default VoiceRecognitionService;
