import React, {useEffect, useContext} from 'react';
import './Desktop.scss';
import Header from '../components/header/Header';
import ProductList from '../components/productList/ProductList';
import MessageLoader from '../components/messageLoader/MessageLoader';
import VoiceRecognitionSwitcher from '../voiceRecognition/VoiceRecognitionSwitcher';
import Chat from '../components/chat/Chat';
import AppContext from '../AppContext';

const Desktop: React.FC = () => {
    const appContext = useContext(AppContext);
    return (
        <div className="desktop-layout">
            <div className="desktop-layout-top">
                <Header/>
            </div>
            <div className="desktop-layout-wrapper">
                <div className="desktop-layout-chat-column">
                  <Chat/>
                </div>
                <div className="desktop-layout-main-product-wrapper">
                    {!appContext.isProductListFetching ? <ProductList/> : <MessageLoader/> }
                </div>
            </div>
            <VoiceRecognitionSwitcher/>
        </div>
    );
};

export default Desktop
