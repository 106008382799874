import React, {useContext, useEffect} from 'react';
import Tags from '../tags/Tags';
import MessageList from '../messageList/MessageList';
import MessageInput from '../messageInput/MessageInput';
import AppContext from '../../AppContext';
import Loader from '../loader/Loader';
import MessageLoader from '../messageLoader/MessageLoader';
import './Chat.scss';

const Chat = () => {
    const myRef = React.createRef<HTMLElement>();
    const appContext = useContext(AppContext);
    const messageLoaderAnimation = appContext.waiting ? <MessageLoader/> : null;

    useEffect(() => {
        if (myRef.current && !appContext.isMobile) {
            myRef.current.scrollTop = myRef.current.scrollHeight;
        }
    }, [appContext.chatState]);

    useEffect(() => {
        if (myRef.current && !appContext.isMobile) {
            myRef.current.scrollTop = myRef.current.scrollHeight;
        }
    }, [appContext.waiting]);

    const chatComponent = appContext.isMobile
        ? (
            <section className="chat-container-messages"
                     ref={myRef}>
                <MessageList/>
                {messageLoaderAnimation}
            </section>)
        : (
            <>
                <section className="chat-container-tags">
                    {appContext.entities.length ? <Tags/> : null}
                </section>
                <section className="chat-container-messages"
                         ref={myRef}>
                    <MessageList/>
                    {messageLoaderAnimation}
                </section>
                <section className="chat-container-messages-input">
                    <MessageInput/>
                </section>
            </>
        );

    return !appContext.isChatLoading ? chatComponent : !appContext.isMobile ? <Loader/> : null;
};

export default Chat;
